:root {
    --primary-blue: #4A90E2;
    --primary-blue-hover: #357ABD;
    --dark-bg: #1e1e2f;
}

.custom-file-upload .p-fileupload-choose:not(.p-disabled) {
    background: var(--primary-blue) !important;
    border-color: var(--primary-blue) !important;
    color: white !important;
}

.custom-file-upload .p-fileupload-choose:not(.p-disabled):hover {
    background: var(--primary-blue-hover) !important;
    border-color: var(--primary-blue-hover) !important;
}

.custom-file-upload .p-fileupload-choose:not(.p-disabled):active {
    background: var(--primary-blue-hover) !important;
    border-color: var(--primary-blue-hover) !important;
}

.custom-file-upload .p-fileupload-choose:not(.p-disabled):focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--primary-blue) !important;
}

.custom-file-upload .p-tag {
    background-color: var(--primary-blue) !important;
    color: white !important;
}

.custom-file-upload .p-button {
    background: var(--primary-blue) !important;
    border-color: var(--primary-blue) !important;
    color: white !important;
}

.custom-file-upload .p-button:hover {
    background: var(--primary-blue-hover) !important;
    border-color: var(--primary-blue-hover) !important;
}

.custom-file-upload .p-fileupload-content {
    background-color: transparent !important;
    color: white !important;
    border: 1px solid rgba(75, 85, 99, 0.6) !important;
}

.custom-file-upload .p-fileupload-row {
    color: inherit !important;
}

.custom-file-upload .p-fileupload-files {
    color: inherit !important;
}

/* Add these styles for button standardization */
.custom-file-upload .p-fileupload-buttonbar {
    display: flex !important;
    justify-content: space-between !important;
    padding: 1rem !important;
    width: 100% !important;
}

.custom-file-upload .p-button,
.custom-file-upload .p-fileupload-choose {
    min-width: 30% !important;
    max-width: 32% !important;
    height: 40px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0.5rem 1rem !important;
    margin: 0 !important;
}

/* Ensure icons are aligned properly */
.custom-file-upload .p-button-icon {
    margin-right: 0.5rem !important;
}

/* Make sure the button text is centered with the icon */
.custom-file-upload .p-button-label {
    flex: 0 0 auto !important;
}

/* Container styling */
.custom-file-upload .p-fileupload {
    padding: 0 !important;
}